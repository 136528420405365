import React from 'react';


export default function Content() {

  return (
    <div className='footer'>
      <div className="container">
      <div className="row d-flex">
        
        <p className="fs-12 m-b-20">
          Old Anandians' Professional Forum, Formally know as OAPF and certain product names used herein are trademarks or registered trademarks of OAPF and/or one of its subsidiaries or affiliates in Sri Lanka and/or other countries. See Trademarks for appropriate markings.
        </p>
        <p className="fs-12">
          Copyright © 2023, Old Anandians' Professional Forum and/or its subsidiaries or affiliates. 
          <br/>
          All Rights Reserved.
          Built and Maintained by OAPF IT Committee along with 
          <a href="https://revox.io/" target="_blank" rel="noopener noreferrer"> Revox Pvt Ltd</a>
         
        </p>
        <a href="https://revox.io/" target="_blank" rel="noopener noreferrer">
        <img src="/assets/images/revox-logo.png"  alt="" width={91} height={22} className="m-t-10" />
        </a>
      </div>
      </div>
    </div>

  )
}
