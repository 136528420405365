import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();


const customStyles = {
	control: (provided, state) => ({
		...provided,
		backgroundColor: state.isDisabled ? "#e9ecef" : "#fff",
		borderColor: state.isDisabled ? "#ced4da" : state.isFocused ? "#80bdff" : "#ced4da",
		cursor: state.isDisabled ? "not-allowed" : "default",
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		opacity: 1
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: state.isDisabled ? "#212529" : provided.color,
		cursor: state.isDisabled ? "not-allowed" : "default",
	}),
};

export default function Content({ options, placeholder, isClearable, value, onChange, disabled }) {
	return (
		<Select
			options={options}
			isClearable={isClearable}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			isDisabled={disabled}
			components={{ animatedComponents, IndicatorSeparator: () => null }}
			styles={customStyles}

		/>
	);
}