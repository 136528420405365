import React, { useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";

export default function Content() {
  const location = useLocation();
  const navigate = useNavigate();

  const [user] = useState(location.state);

  const toMembers = () => {
    navigate(`/members`);
    window.location.reload();
  };

  return (
    <>

      {user ?
        <div className="payment-summary">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 p-t-r">
                <div>
                <img src="/assets/images/logo-glyph-gold_c.png" alt=" " width={65} height={69} className="m-b-10" />
                  <h2 className="m-t-30 m-b-20">{user.title}. {user.lastName}</h2>
                  <h1 className="m-b-25 title-h1 mx-w-70">Application received and is under review</h1>

                  <p className="sm m-w-60 m-b-25">We are pleased to inform you that we have submitted your application to the Charter Committee to review it.</p>
             
                </div>
              </div>

              <div className="payment-card col-lg-6">
                <p className="fs-16 m-b-30">Thank you for your interest in Old Anandians’ Professional Forum. Our mission is to help everyone find their rightful place in the world of Ananda College. Talented individuals like you, inspire us and grow together. We appreciate the time and effort that you put into the application process. If your application align with our guidelines a recruiter will be in touch with you soon. Thank you again for your interest in joining OAPF.</p>

                <p className="overline-m m-b-20">WHATS NEXT?</p>
                <p className="fs-16 m-b-30">Your application will be submitted to the charter committee for review.
                  If your application is approved, you will receive an email containing a payment link to proceed with the payment process.</p>

                <p className="fs-13 m-b-30">The charter committee will carefully evaluate your application, and we will notify you promptly of their decision. Stay tuned for further updates, and we appreciate your patience throughout this process.</p>

                <div className="card-buttons m-b-40">
                  <button className='btn btn-payment' onClick={() => toMembers()}> <img src="/assets/images/arrow-back.svg" className="position-ab" alt=''></img><span className="p-l-20">back to homepage</span></button>
                </div>

              </div>

            </div>
          </div>

        </div>

        : <p className='d-flex align-items-center justify-content-center fs-18 v-h-80'>This page is not found</p>}
    </>
  )
}
