
import React from "react";
import { BrowserRouter, useRoutes , Navigate} from "react-router-dom";

import Members from './pages/Members';
import Register from "./pages/Register";
import Login from "./pages/Login";
import MemberInner from "./pages/MemberInner";
import PaymentSummary from "./pages/PaymentSummary";
import PaymentSuccessful from "./pages/PaymentSuccessful";
import ApplicationAccepted from "./pages/ApplicationAccepted";

import Payment from "./pages/PaymentCheck";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { AuthProvider } from "./components/AuthProvider/AuthProvider";
import './App.scss';

const App = () => {

  let path = window.location.pathname;

  const AppRoutes = () => {
    let routes = useRoutes([
      { path: "/register", element: <Register /> },
      { path: "/admin", element: <Login /> },
      { path: "/members", element: <Members /> },
      { path: "/member-inner/:id", element: <MemberInner /> },
      { path: "/review", element: <ApplicationAccepted /> },
      { path: "/payment-summary/:id", element: <PaymentSummary /> },
      { path: "/payment-successful/:id", element: <PaymentSuccessful /> },
      { path: "/payment/:id", element: <Payment /> },
      { path: "/", element: <Navigate to="/members" replace /> }
    ]);
    return routes;
  };

  return (
    <BrowserRouter>
      <div className="page-container">
        <AuthProvider>
          {
            !path.includes('review') &&
            !path.includes('payment') &&
            <Header />
          }

          <div className="content-wraper">
            <AppRoutes />
          </div>
          <Footer />
        </AuthProvider>
      </div>
    </BrowserRouter>
  );
};

export default App;
