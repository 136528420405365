import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import Select from '../../components/Select';
import CreatableSelect from '../../components/CreatableSelect';
import ImageUploader from '../../components/ImageUploader';
import { titleOptions, professionList } from '../../stores/data';
import { variables } from "../../stores/backend";
import { useFormik } from 'formik';
import { useAuth } from '../../components/AuthProvider/AuthProvider';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const validationSchema = yup.object().shape({
    title: yup.string().required('This field is required'),
    firstName: yup.string().required('This field is required'),
    lastName: yup.string().required('This field is required'),
    fullName: yup.string().required('This field is required'),
    dob: yup.string().required('This field is required'),
    cardName: yup.string().required('This field is required'),
    streetAddress1: yup.string().required('This field is required'),
    city: yup.string().required('This field is required'),
    province: yup.string().required('This field is required'),
    zipCode: yup.string().required('This field is required'),
    email: yup.string().email('Invalid email').required('This field is required'),
    confirmEmail: yup.string().email('Invalid email').required('This field is required').oneOf([yup.ref('email')], 'Emails should be match'),
    mobileNumber: yup.string().required('This field is required').matches(/^\+[0-9]+$/, 'Invalid mobile number'),
    idNumber: yup.string(),
    profession: yup.string().required('This field is required'),
    designation: yup.string().required('This field is required'),
    position: yup.string().required('This field is required'),
    reference: yup.string().required('This field is required'),
    imageURL: yup.string().required('This field is required'),
    rescaledImageURL: yup.string(),
    recaptcha: yup.string().required('Please complete the reCAPTCHA'),
});

export default function Form() {
    const { isAdmin } = useAuth();
    const formik = useFormik({
        initialValues: {
            title: '',
            firstName: '',
            lastName: '',
            fullName: '',
            dob: '',
            cardName: '',
            streetAddress1: '',
            streetAddress2: '',
            city: '',
            province: '',
            zipCode: '',
            email: '',
            confirmEmail: '',
            mobileNumber: '',
            idNumber: '',
            company: '',
            profession: '',
            designation: '',
            position: '',
            imageURL: '',
            rescaledImageURL: '',
            ocbaNumber: '',
            recaptcha: '',
            reference: '',
            imOnWhatsapp: false
        },
        validationSchema,
        onSubmit: async (data, { setFieldValue }) => {
            // Handle form submission

            const body = {
                "title": data?.title,
                "foreName": data?.firstName,
                "lastName": data?.lastName,
                "fullName": data?.fullName,
                "dateOfBirth": data?.dob,
                "nameOnCard": data?.cardName,
                "address": {
                    "address1": data?.streetAddress1,
                    "address2": data?.streetAddress2,
                    "city": data?.city,
                    "state": data?.province,
                    "zip": data?.zipCode,
                },
                "email": data?.email,
                "mobileNumber": data?.mobileNumber,
                "idORPassportNumber": data?.idNumber,
                "acobaNumber": data?.ocbaNumber,
                "reference": data?.reference,
                "preferredPosition": data?.position,
                "employer": data?.company,
                "profession": data?.profession,
                "designation": data?.designation,
                "profilePic": data?.imageURL,
                "rescaledProfilePic": data?.rescaledImageURL,
                "imOnWhatsapp": data?.imOnWhatsapp,
            }

            if (data.submitType === 'normal') {
                const response = await fetch(`${variables.basePath}/api/auth/register`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(body),
                });
                const res = await response.json();
                if (res.success) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'You have successfully registered',
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                    navigate(`/review`, { state: res.result });

                } else {
                    if (res.result === "email exists") {
                        Swal.fire({
                            title: 'Email already exists!',
                            text: 'Please try with different email address',
                            icon: 'warning',
                            confirmButtonText: 'Okay'
                        });
                    } else {
                        Swal.fire({
                            title: 'Something went wrong!',
                            text: 'Please try again later',
                            icon: 'warning',
                            confirmButtonText: 'Okay'
                        })
                    }
                }

            } else if (data.submitType === 'vip') {
                const response = await fetch(`${variables.basePath}/api/auth/register/vip`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "secret_token": token,
                    },
                    body: JSON.stringify(body),
                });
                const res = await response.json();
                if (res.success) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'You have successfully registered',
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                    navigate(`/members`);

                } else {
                    if (res.result === "email exists") {
                        Swal.fire({
                            title: 'Email already exists!',
                            text: 'Please try with different email address',
                            icon: 'warning',
                            confirmButtonText: 'Okay'
                        });
                    } else {
                        Swal.fire({
                            title: 'Something went wrong!',
                            text: 'Please try again later',
                            icon: 'warning',
                            confirmButtonText: 'Okay'
                        })
                    }
                }

            }

        },
    });


    const navigate = useNavigate();
    const [showUploader, setShowUploader] = useState(false);
    const [imageURL, setImageURL] = useState("");
    const [maximumSizeError, setMaximumSizeError] = useState(false);
    const [token] = useState(sessionStorage.getItem('token'));
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
    }, [showUploader])

    const showUpload = () => {
        setShowUploader(true);
    };

    const handleImageURL = (value) => {
        formik.setFieldValue('imageURL', value?.url);
        formik.setFieldValue('rescaledImageURL', value?.rescaledURL);
        setImageURL(value?.url);
    };

    const handlePhoneNumber = (value) => {
        console.log(value);
        formik.setFieldValue('mobileNumber', value);
        setPhoneNumber(value);
    };

    const handleModal = (value) => {
        setShowUploader(value);
    };

    return (
        <div className="">

            <form onSubmit={formik.handleSubmit} className="m-b-60">
                <p className="overline-m p-b-20 m-b-10">basic information</p>
                <div className="row">
                    <div className="col-md-2 m-b-10">
                        <label htmlFor="">Title</label>

                        <CreatableSelect
                            className="form-control"
                            isClearable={true}
                            name="title"
                            placeholder="Select"
                            options={titleOptions}
                            value={titleOptions.find((option) => option.value === formik.values.title)}
                            onChange={(selectedOption) =>
                                formik.setFieldValue('title', selectedOption?.value || '')
                            }
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.title && formik.errors.title && (
                            <span className="text-danger fs-14">{formik.errors.title}</span>
                        )}
                    </div>
                    <div className="col-md-5 m-b-10">
                        <label htmlFor="">First Name</label>
                        <input
                            className="form-control"
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.firstName && formik.errors.firstName && (
                            <span className="text-danger fs-14">{formik.errors.firstName}</span>
                        )}
                    </div>
                    <div className="col-md-5 m-b-5 ">
                        <label htmlFor="">Last Name</label>
                        <input
                            className="form-control"
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                            <span className="text-danger fs-14">{formik.errors.lastName}</span>
                        )}

                    </div>
                    <p className="fs-13 hint-text m-t-0 m-b-25">This is the name that will be printed on your membership card. Enter one single name for each fields</p>
                    <div className="col-md-12 m-b-15">
                        <label htmlFor="">Full Name</label>
                        <input
                            className="form-control"
                            type="text"
                            name="fullName"
                            placeholder="Full Name"
                            value={formik.values.fullName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.fullName && formik.errors.fullName && (
                            <span className="text-danger fs-14">{formik.errors.fullName}</span>
                        )}

                    </div>

                    <div className="col-md-12 m-b-15">
                        <label htmlFor="">Name with Initials</label>
                        <input
                            className="form-control"
                            type="text"
                            name="cardName"
                            placeholder="Name with Initials"
                            value={formik.values.cardName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.cardName && formik.errors.cardName && (
                            <span className="text-danger fs-14">{formik.errors.cardName}</span>
                        )}

                    </div>

                    <div className="col-md-7 m-b-15">
                        <label htmlFor="">Date of Birth</label>
                        <input
                            className="form-control"
                            type="date"
                            name="dob"
                            placeholder="Date of Birth"
                            value={formik.values.dob}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.dob && formik.errors.dob && (
                            <span className="text-danger fs-14">{formik.errors.dob}</span>
                        )}
                    </div>

                    <p className="fs-14 m-t-10">Residential Address</p>
                    <div className="col-md-6 m-b-15">
                        <input
                            className="form-control"
                            type="text"
                            name="streetAddress1"
                            placeholder="Street Address 1"
                            value={formik.values.streetAddress1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.streetAddress1 && formik.errors.streetAddress1 && (
                            <span className="text-danger fs-14">{formik.errors.streetAddress1}</span>
                        )}

                    </div>
                    <div className="col-md-6 m-b-15">
                        <input
                            className="form-control"
                            type="text"
                            name="streetAddress2"
                            placeholder="Street Address 2"
                            value={formik.values.streetAddress2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.streetAddress2 && formik.errors.streetAddress2 && (
                            <span className="text-danger fs-14">{formik.errors.streetAddress2}</span>
                        )}

                    </div>
                    <div className="col-md-4 m-b-15">
                        <input
                            className="form-control"
                            type="text"
                            name="city"
                            placeholder="City"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.city && formik.errors.city && (
                            <span className="text-danger fs-14">{formik.errors.city}</span>
                        )}

                    </div>
                    <div className="col-md-4 m-b-15">
                        <input
                            className="form-control"
                            type="text"
                            name="province"
                            placeholder="State/Province/Region"
                            value={formik.values.province}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.province && formik.errors.province && (
                            <span className="text-danger fs-14">{formik.errors.province}</span>
                        )}

                    </div>
                    <div className="col-md-4 m-b-15">
                        <input
                            className="form-control"
                            type="text"
                            name="zipCode"
                            placeholder="Zip Code"
                            value={formik.values.zipCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.zipCode && formik.errors.zipCode && (
                            <span className="text-danger fs-14">{formik.errors.zipCode}</span>
                        )}

                    </div>


                    <p className="fs-16 m-t-10">Email</p>
                    <div className="col-md-6 m-b-15">
                        <input
                            className="form-control"
                            type="text"
                            name="email"
                            placeholder="Email Address"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && (
                            <span className="text-danger fs-14">{formik.errors.email}</span>
                        )}

                    </div>
                    <div className="col-md-6 m-b-10">
                        <input
                            className="form-control"
                            type="text"
                            name="confirmEmail"
                            placeholder="Confirm Email"
                            value={formik.values.confirmEmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.confirmEmail && formik.errors.confirmEmail && (
                            <span className="text-danger fs-14">{formik.errors.confirmEmail}</span>
                        )}

                    </div>
                    <p className="fs-13 hint-text m-b-25">Your Payment reciepts, membership card and other information will be communicated through this email</p>

                    <div className="col-md-6">
                        <label htmlFor="">Mobile Number</label>
                        <div className="row">
                            <div className="m-b-15">
                                <PhoneInput
                                    className="form-control d-flex"
                                    international
                                    defaultCountry="LK"
                                    countryCallingCodeEditable={false}
                                    value={phoneNumber}
                                    onChange={handlePhoneNumber} />
                                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                                    <span className="text-danger fs-14">{formik.errors.mobileNumber}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 m-b-30">
                        <input
                            type="checkbox"
                            name="imOnWhatsapp"
                            id="flexCheckDefault"
                            className="form-check-input"
                            checked={formik.values.imOnWhatsapp}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <label className="m-l-10 form-check-label" htmlFor="flexCheckDefault">I’m on WhatsApp on this number</label>
                    </div>

                    <div className="col-md-6 m-b-15">
                        <label htmlFor="">ID Number/Passport Number</label>
                        <input
                            className="form-control"
                            type="text"
                            name="idNumber"
                            placeholder="ID Number/Passport Number"
                            value={formik.values.idNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.idNumber && formik.errors.idNumber && (
                            <span className="text-danger fs-14">{formik.errors.idNumber}</span>
                        )}

                    </div>

                    <div className="col-md-6 m-b-15">
                        <label htmlFor="">ACOBA Number (Optional)</label>
                        <input
                            className="form-control"
                            placeholder="XXXXX"
                            name="ocbaNumber"
                            value={formik.values.ocbaNumber}
                            onChange={formik.handleChange}
                        />
                    </div>


                    <div className="col-md-12 m-b-15 d-flex flex-column">


                        <label htmlFor="">Upload your Profile/ID Photo</label>
                        <p className="fs-13 hint-text m-b-10 mx-w-60">This photograph will serve as your membership card ID photo. Kindly upload a photo that adheres to the standard guidelines for passport/ID photographs. </p>
                        <img src="assets/images/guide-photo.png" className='id-guide' alt="Passport Guide" ></img>

                        <p className='upload-button form-control w-33' onClick={showUpload}>
                            Upload Image
                            <span className="material-icons-outlined">
                                file_upload
                            </span>
                        </p>
                        <div>
                            {formik.touched.imageURL && formik.errors.imageURL && (
                                <span className="text-danger fs-14">{formik.errors.imageURL}</span>
                            )}
                        </div>
                        <div>
                            {maximumSizeError && (
                                <span className="text-danger fs-14">Maximum image size should be 5MB. Please choose a smaller image.</span>
                            )}
                        </div>

                        {imageURL ? <img src={imageURL} className="uploaded-pic m-t-10" alt="" width={100} height={100} /> : null}
                    </div>



                    <p className="overline-m  p-t-35 p-b-10 m-b-20">Professional Information</p>

                    <div className="col-md-12 m-b-15">
                        <label htmlFor="">Name of Employer/Company</label>
                        <input
                            className="form-control"
                            type="text"
                            name="company"
                            placeholder="Name of Employer/Company"
                            value={formik.values.company}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.company && formik.errors.company && (
                            <span className="text-danger fs-14">{formik.errors.company}</span>
                        )}
                    </div>

                    <div className="col-md-6 m-b-15">
                        <label htmlFor="">Profession</label>
                        <input
                            className="form-control"
                            type="text"
                            name="profession"
                            placeholder="Profession"
                            value={formik.values.profession}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.profession && formik.errors.profession && (
                            <span className="text-danger fs-14">{formik.errors.profession}</span>
                        )}
                    </div>
                    <div className="col-md-6 m-b-15">
                        <label htmlFor="">Designation</label>
                        <input
                            className="form-control"
                            type="text"
                            name="designation"
                            placeholder="Designation"
                            value={formik.values.designation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.designation && formik.errors.designation && (
                            <span className="text-danger fs-14">{formik.errors.designation}</span>
                        )}
                    </div>

                    <div className="col-md-12 m-b-10">
                        <label htmlFor="">Preferred Position at OAPF</label>
                        <Select
                            className="form-control"
                            isClearable={true}
                            name="title"
                            placeholder="Select Subcommittee"
                            options={professionList}
                            value={professionList.find((option) => option.value === formik.values.position)}
                            onChange={(selectedOption) =>
                                formik.setFieldValue('position', selectedOption?.value || '')
                            }
                        />
                        {formik.touched.position && formik.errors.position && (
                            <span className="text-danger fs-14">{formik.errors.position}</span>
                        )}

                    </div>
                    <p className="fs-13 hint-text m-b-25 mx-w-60">You will be positioned in this Sub Committee inside OAPF</p>

                    <div className="col-md-12 m-b-10">
                        <label htmlFor="">Reference</label>
                        <input
                            className="form-control"
                            placeholder="Name of an existing OAPF Member"
                            name="reference"
                            value={formik.values.reference}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.reference && formik.errors.reference && (
                            <span className="text-danger fs-14">{formik.errors.reference}</span>
                        )}
                    </div>

                    <p className="fs-13 hint-text m-b-35 mx-w-60">The Reference field will expedite the process of verifying your identity as an Anandian. Kindly provide the name of an existing OAPF Member who can vouch for you. This will aid us in streamlining the authentication procedure.  </p>

                    <div className="col-md-12 m-b-15">
                        <ReCAPTCHA
                            sitekey="6LdUIJ0mAAAAAKq8wBCLbf2R8McLsUm9_Y5HQqyH"
                            onChange={(value) => formik.setFieldValue('recaptcha', value)}
                        />
                        {formik.touched.recaptcha && formik.errors.recaptcha && (
                            <span className="text-danger fs-14">{formik.errors.recaptcha}</span>
                        )}
                    </div>

                </div>
                <br />
                <div className="row">
                    {!isAdmin &&
                        <div className="col-md-8 m-b-10">
                            <p className="fs-12 hint-text m-r-25 m-t-5">By submitting to a one-time membership to the Old Anandians' Professional Forum (OAPF), you agree to abide by the terms and conditions set forth in this form.</p>
                        </div>
                    }
                    {!isAdmin &&
                        <div className="col-md-4 m-b-30 p-b-30 d-grid">
                            <button type="submit" className="btn btn-register" onClick={() => formik.setFieldValue('submitType', 'normal')}>Submit</button>
                            {!isAdmin && !formik.isValid &&
                                <span className='m-t-10 text-danger fs-14'>Please scroll up and fill out all required fields. </span>
                            }
                        </div>
                    }

                    {isAdmin &&
                        <div className="col-md-12 m-b-30 p-b-30 d-grid">
                            <button type="submit" className="btn btn-register" onClick={() => formik.setFieldValue('submitType', 'vip')}>VIP Registration</button>
                            {isAdmin && !formik.isValid &&
                                <span className='m-t-10 text-danger fs-14'>Please scroll up and fill out all required fields. </span>
                            }
                        </div>
                    }
                </div>

            </form>
            <ImageUploader show={showUploader} returnURL={handleImageURL} handleModal={handleModal} setMaximumSizeError={setMaximumSizeError} />
        </div>

    );
}
