import React, { useState, useRef, useEffect } from "react";
import { ProgressBar, Button, Modal } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { variables } from "../../stores/backend"
import Cropper from "react-cropper";
import './cropper.scss';

function Content({ show, returnURL, handleModal, existingImage, setMaximumSizeError }) {
  const [modalShow, setModalShow] = useState(show);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(existingImage);
  const cropperRef = useRef(null);
  const [upload, setUpload] = useState(false);

  useEffect(() => {
    setModalShow(show);
  }, [show, existingImage])

  const onFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleModalHide = (value) => {
    handleModal(value);
    setModalShow(value);

  };

  const onUpload = async () => {
    setUpload(true);
    setMaximumSizeError(false);
    setShowProgress(true);

    try {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      const blobPromise = new Promise((resolve) => {
        croppedCanvas.toBlob((blob) => {
          resolve(blob);
        });
      });

      const blob = await blobPromise;
      const formData = new FormData();
      formData.append("file", blob);

      const response = await fetch(`${variables.basePath}/api/user/propic/upload`, {
        method: "POST",
        body: formData,
        onUploadProgress: (event) => {
          const progress = Math.round((event.loaded / event.total) * 100);
          setProgress(progress);
        }
      });

      if (response.status === 200) {
        const result = await response.json();
        setProgress(100);
        returnURL(result);
      } else if (response.status === 413) {
        setMaximumSizeError(true);
        throw new Error("Failed to upload image.");
      } else {
        // need to add differnt error message here
        setMaximumSizeError(true);
        throw new Error("Failed to upload image.");
      }
      setUpload(false);

    } catch (error) {
      console.log(error);
    }

    const debouncedFunction1 = debounce(setShowProgress, 2000);
    const debouncedFunction2 = debounce(setProgress, 2000);
    const debouncedFunction3 = debounce(handleModalHide, 2000);
    debouncedFunction1(false);
    debouncedFunction2(0);
    debouncedFunction3(false);
  };

  return (
    <div>

      <Modal
        show={modalShow}
        onHide={() => handleModalHide(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Upload your ID Photo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            className="form-control m-t-5 m-b-5"
            onChange={onFileChange}
            accept="image/*"
            placeholder="Browse Files"
          />
          {uploadedImage && (
            <Cropper
              src={uploadedImage}
              style={{ height: 400, width: "100%" }}
              initialAspectRatio={1 / 1}
              aspectRatio={1 / 1}
              guides={false}
              // crop={onCrop}
              ref={cropperRef}
            />
          )}

          {showProgress ? <ProgressBar className="m-t-10 w-100" now={progress} label={`${progress}%`} /> : null}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onUpload} disabled={upload}>
            {upload ? 'Uploading' : 'Upload'}
          </Button>
        </Modal.Footer>
      </Modal>



    </div>
  );
}


export default Content;