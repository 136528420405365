import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Offcanvas } from 'react-bootstrap';
import { useAuth } from '../AuthProvider/AuthProvider';

export default function HeaderMobile() {

    const navigate = useNavigate();
    const { isAdmin } = useAuth();
    const [admin] = useState(isAdmin ? JSON.parse(sessionStorage.getItem("adminUser")) : null)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const logout = () => {
        sessionStorage.clear()
        navigate(`/members`);
        window.location.reload();
    };

    return (
        <div className="header-mobile">
            <img src="/assets/images/logos@2x.png" alt="" width={112} />
            <div className='mobile d-flex justify-content-end'>
                <span className="material-icons-outlined" onClick={handleShow}>menu</span>
            </div>

            <Offcanvas show={show} onHide={handleClose} scroll={true} placement='end'>
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                    <NavLink to="home" className="menu-link" activeclassname='active' >
                        Home
                    </NavLink>
                    <br />
                    <NavLink to="projects" className="menu-link" activeclassname='active' >
                        Projects
                    </NavLink>
                    <br />
                    <NavLink to="about" className="menu-link" activeclassname='active' >
                        About
                    </NavLink>
                    <br />
                    <NavLink to="members" className="menu-link" activeclassname='active' >
                        Members
                    </NavLink>
                    <br />
                    <NavLink to='register' className="menu-link" activeclassname='active' >
                        Become a member
                    </NavLink>
                    <br />
                    {isAdmin &&
                        <>
                            <button className="mobile-button" onClick={() => { navigate(`/member-inner/${admin?._id}`, { state: admin }) }}>
                                User Profile
                            </button>
                            <br />
                            <button className="mobile-button" onClick={() => logout()} >
                                Logout
                            </button>
                            <br />
                        </>
                    }
                </Offcanvas.Body>
            </Offcanvas>


        </div>
    )
}


