import React from 'react'

export default function StaticContent() {
  return (
    <div className="innerin">
      <p className="overline m-b-25">You are applying for</p>
      <div className="d-flex">
        <img src="/assets/images/Oapf-badge.png" alt="" width={40} height={"100%"} className="m-r-10" />
        <div>
          <h3 className='m-b-10'>Life Member</h3>
          <p className='sm m-b-20 hint-text'>Life member status provides a unique opportunity for acknowledging
            unique personals who qualify for special recognition.</p>
          <p className="overline-sm hint-text">Membership fee</p>
          <h3 className='m-b-0'>LKR 10,000</h3>
          <p className="sm fs-13">Forever</p>
        </div>
      </div>
      <p className="md m-t-30 m-b-15">For Ananda College Alumni only</p>
      <p className="fs-14">Your data will be shared with OAPF.org for membership fulfilment.</p>

      <p className="fs-14">
        If you are resident abroad please enter your passport number.</p>

      <p className="fs-14">
        If this form is not acknowledged by OAPF please send an email to membership@oapf.org</p>

      <p className="fs-14">
        When your application is approved, you may be advised to pay the membership fee.</p>

      <p className="fs-14 m-b-20">
        We&rsquo;ll only use your information to reply to you regarding the subjects in this message. No marketing spam. Ever.</p>

      <p className="overline-sm p-t-20 p-b-10 m-t-30">Terms and Conditions</p>
      <ol className="fs-12">
        <li>By purchasing a one-time membership to the Old Anandians&#39; Professional Forum (OAPF), you agree to abide by the terms and conditions set forth in this agreement.</li>
        <li>The membership fee for OAPF is 10,000 LKR, payable in full at the time of application. This fee is non-refundable and non-transferable.</li>
        <li>Membership in OAPF is open to individuals who have graduated from Ananda College, Colombo, and who are currently engaged in a professional occupation. Membership is subject to approval by the OAPF Executive Committee.</li>
        <li>Life Membership in OAPF is valid for life time.</li>
        <li>As a member of OAPF, you agree to participate actively in the activities and events organized by the Forum. You also agree to contribute to the goals and objectives of the Forum to the best of your ability.</li>
        <li>OAPF reserves the right to terminate or suspend the membership of any member who violates the terms and conditions set forth in this agreement or engages in behavior that is detrimental to the reputation of the Forum.</li>
        <li>OAPF is a private charity organization, and all membership fees and donations are used to support the activities and initiatives of the Forum.</li>
        <li>By joining OAPF, you acknowledge that you have read, understood, and agreed to the terms and conditions set forth in this agreement. You also agree to comply with all rules, regulations, and policies of OAPF, as amended from time to time.</li>
        <li>This agreement shall be governed by and construed in accordance with the laws of Sri Lanka, and any disputes arising under this agreement shall be resolved exclusively by the courts of Sri Lanka.</li>
        <li>OAPF reserves the right to modify or amend the terms and conditions of this agreement at any time, and such modifications or amendments shall be effective upon posting on the OAPF website. Members are responsible for reviewing the website periodically to ensure that they are aware of any changes to this agreement.</li>
        <li>No Refunds: Once the payment for membership fees has been made, no refunds will be granted. We have a strict no-refund policy in place, and all membership fees are non-refundable. <br/><a href="https://www.oapf.org/terms-and-conditions/" style={{ color : 'black'}}>Refund Policy</a></li>
      </ol>
    </div>
  )
}
