import React, { useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Modal } from 'react-bootstrap';
import { variables } from "../../stores/backend";

export default function Content() {
  const location = useLocation();
  const navigate = useNavigate();

  const [contactInfo] = useState(location.state);
  const [modalIsOpen, setIsOpen] = useState(false);
  const clientID = parseInt(variables.clientID);
  const paymentAmount = parseInt(variables.paymentAmount);

  if (contactInfo) {
    contactInfo.OAPFNumber = contactInfo?.OAPFNumber.toString().substring(0, 4) + 'XXXXXXXX';
  }

  function buildPayment() {
    return {
      clientId: clientID,
      paymentAmount: paymentAmount,
      currency: 'LKR',
      returnUrl: variables.returnURL,
      clientRef: contactInfo._id,
      comment: 'OAPF membership payment'
    };
  }
  function openModal() {
    setIsOpen(true);
    loadPaycorpPayment(buildPayment(), 'paycorp-payment');
  }

  async function closeModal() {
    if (contactInfo) {
      // API call
      const response = await fetch(`${variables.basePath}/api/payment/check/${contactInfo._id}`, {
        method: "GET",
      });
      const res = await response.json();
      if (res.responseCode===10) {
        navigate(`/payment-successful/${contactInfo._id}`, { state: contactInfo });
      }

    }
    setIsOpen(false);
  }

  async function makePayment() {
    openModal()
  }

  function loadPaycorpPayment(payment, elementId) {
    // Load paycorp_payments.js from the CDN or the appropriate source.
    const script = document.createElement('script');
    script.src = 'https://sampath.paycorp.lk/webinterface/qw/paycorp_payments.js';
    script.async = true;
    script.onload = () => {
      // Call the Paycorp payment function once the script is loaded.
      window.loadPaycorpPayment(payment, elementId);
    };
    document.body.appendChild(script);
  }

  return (
    <>
      <Modal show={modalIsOpen} onHide={closeModal} centered size="lg" className='payment-modal'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="h-100 w-100">
            <div id="paycorp-payment" className="paycorp-payment"></div>
          </div>
        </Modal.Body>
      </Modal>

      {contactInfo ?
        <div className="payment-summary">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 p-t-r">
                <div>
                <img src="/assets/images/logo-glyph-gold_c.png" alt=" " width={65} height={69} className="m-b-25" />
                  <h2 className="m-b-0">Congratulations</h2>
                  <h2 className="m-b-20">{contactInfo?.title}. {contactInfo?.lastName}</h2>
                  <h1 className="m-b-25 title-h1">Your<br /> application<br /> is accepted.</h1>

                  <p className="sm m-w-60 m-b-25">We are pleased to inform you that you have been nominated by the Charter Committee and accepted, to be a Life Member of the “Old Anandians’ Professional Forum” (OAPF)</p>
              
                </div>
              </div>

              <div className="d-flex d-block d-lg-none d-xl-none d-xl-block flex-wrap justify-content-center p-t-30 p-b-20">

<div className="flip-card" tabIndex="0">
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <div className="membership-card-front   ">
        <p className="card-name">{contactInfo?.title}. {contactInfo?.foreName} <br /> {contactInfo?.lastName}</p>
        <p className="card-number">{contactInfo?.OAPFNumber}</p>
        <p className="card-id">{contactInfo?.idORPassportNumber}</p>
        <p className="card-paid">Not Paid</p>
        <img src={contactInfo?.profilePic} alt="" className="card-profile" />
      </div>
    </div>
    <div className="flip-card-back">
      <div className="membership-card-back">
        <p className="card-secret-number">
          L&lt;M&lt;
          {dayjs(contactInfo?.createdAt).format('YYYY')}&lt;
          {dayjs(contactInfo?.createdAt).format('MM')}&lt;
          {dayjs(contactInfo?.createdAt).format('DD')}&lt;&lt;&lt;
          {contactInfo?.foreName}&lt;
          {contactInfo?.lastName}&lt;&lt;&lt;&lt;
          {contactInfo?.idORPassportNumber}&lt;&lt;&lt;&lt;
          {contactInfo?.OAPFNumber}&lt;&lt;&lt;&lt;
          {contactInfo?.OAPFNumber}&lt;&lt;&lt;&lt;
          {contactInfo?.OAPFNumber}&lt;&lt;&lt;&lt;
          {contactInfo?.OAPFNumber}&lt;
        </p>
        <p className="card-signature">{contactInfo?.lastName}</p>
        <img src="/assets/images/qr-code.png" alt="" className="card-qrcode" />
        <img src={contactInfo?.profilePic} alt="" className="card-profile" />
      </div>
    </div>
  </div>
</div>
<p className="fs-12 text-hint text-center m-t-25"> Need to update any details on your card? No need to worry! We offer the flexibility to make changes even after your payment has been processed. Simply send us a request at <u>membership@oapf.org</u>, and we will gladly assist you with updating the necessary information.</p>
</div>

              <div className="payment-card col-lg-4">

                <p className="overline-m m-b-20">WHATS NEXT?</p>
                <p className="fs-16 m-b-30">To finalize your membership, we kindly request you to make the payment for the membership fees using your debit/credit card. Your commitment to becoming a Life Member is greatly appreciated, and we look forward to welcoming you officially as a Life Member at OAPF.</p>
                <div className="card-header">
                  <p className="overline-sm m-b-20">PAYMENT DETAILS</p>
                </div>
                <div className="card-description">
                  <p className="hint-text overline-sm m-b-0">Payment Status</p>
                  <p className="fs-14 m-b-0 text-orange">Pending</p>
                </div>
                <div className="card-description">
                  <p className="hint-text overline-sm m-b-0">Life Membership fee</p>
                  <p className="fs-14 m-b-0">LKR 10,000</p>
                </div>
                <div className="card-description m-b-10">
                  <p className="hint-text overline-sm m-b-0">Convenience fee </p>
                  <p className="fs-14 m-b-0">LKR 267</p>
                </div>
                <div className="card-total">
                  <p className="m-b-0">Total</p>
                  <p className="m-b-0">LKR 10,267</p>
                </div>

                <div className="card-description m-b-10 m-t-15">
                  <p className="hint-text overline-sm m-b-0">Pay with</p>
                  <img src="/assets/images/pay-logos_c.png" alt=" " width={88} height={25} className="m-b-10" />
                </div>

                <div className="card-buttons">
                  <button className='btn btn-payment' onClick={() => makePayment()}><span className="p-l-20">Pay - LKR 10,267</span> <img src="/assets/images/arrow-forward-03.svg" alt=''></img></button>
                </div>
                <p className="fs-12 text-hint m-t-10 text-center m-b-25">By purchasing a one-time membership to the Old Anandians' Professional Forum (OAPF), you agree to abide by the<a href="https://www.oapf.org/terms-and-conditions/" style={{ color : 'black'}}> terms and conditions.</a></p>
                <p className="footer-pay fs-12 text-hint text-center m-b-0"> Payments are processed by <span> <img src="/assets/images/sampath-logo.png" alt="" width={149} height={24} className="m-l-5" /></span></p>
                <p className="fs-12 text-hint text-center">Secure payment gateway.</p>
              </div>


              <div className="payment-content d-none d-lg-block d-xl-block col-lg-4 m-t-10">
                <div className="col-md-9 p-t-r d-flex flex-column align-items-end flex-wrap mx-auto">

                  <div className="d-flex flex-wrap justify-content-center">

                    <div className="flip-card" tabIndex="0">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <div className="membership-card-front   ">
                            <p className="card-name">{contactInfo?.title}. {contactInfo?.foreName} <br /> {contactInfo?.lastName}</p>
                            <p className="card-number">{contactInfo?.OAPFNumber}</p>
                            <p className="card-id">{contactInfo?.idORPassportNumber}</p>
                            <p className="card-paid">Not Paid</p>
                            <img src={contactInfo?.profilePic} alt="" className="card-profile" />
                          </div>
                        </div>
                        <div className="flip-card-back">
                          <div className="membership-card-back">
                            <p className="card-secret-number">
                              L&lt;M&lt;
                              {dayjs(contactInfo?.createdAt).format('YYYY')}&lt;
                              {dayjs(contactInfo?.createdAt).format('MM')}&lt;
                              {dayjs(contactInfo?.createdAt).format('DD')}&lt;&lt;&lt;
                              {contactInfo?.foreName}&lt;
                              {contactInfo?.lastName}&lt;&lt;&lt;&lt;
                              {contactInfo?.idORPassportNumber}&lt;&lt;&lt;&lt;
                              {contactInfo?.OAPFNumber}&lt;&lt;&lt;&lt;
                              {contactInfo?.OAPFNumber}&lt;&lt;&lt;&lt;
                              {contactInfo?.OAPFNumber}&lt;&lt;&lt;&lt;
                              {contactInfo?.OAPFNumber}&lt;
                            </p>
                            <p className="card-signature">{contactInfo?.lastName}</p>
                            <img src="/assets/images/qr-code.png" alt="" className="card-qrcode" />
                            <img src={contactInfo?.profilePic} alt="" className="card-profile" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="fs-12 text-hint text-center m-t-25"> Need to update any details on your card? No need to worry! We offer the flexibility to make changes even after your payment has been processed. Simply send us a request at <u>membership@oapf.org</u>, and we will gladly assist you with updating the necessary information.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        : <p className='d-flex align-items-center justify-content-center fs-18 v-h-80'>This page is not found</p>}
    </>
  )
}
