import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Dropdown, Form } from 'react-bootstrap';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import debounce from 'lodash.debounce';
import Select from '../../components/Select';
import CreatableSelect from '../../components/CreatableSelect';
import ImageUploader from '../../components/ImageUploader';
import { useAuth } from '../../components/AuthProvider/AuthProvider';
import { titleOptions, professionList } from '../../stores/data';
import { variables } from "../../stores/backend";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

export default function Content() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  const [contactInfo, setContactInfo] = useState(location.state);
  const [editEnable, setEditEnable] = useState(false);
  const [showUploader, setShowUploader] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [token] = useState(sessionStorage.getItem('token'));
  const [maximumSizeError, setMaximumSizeError] = useState(false);

  useEffect(() => {
  }, [showUploader])

  const showUpload = () => {
    setShowUploader(true);
  };

  if (contactInfo === null) {
    const debouncedGetUser = debounce(getUser, 1000);
    debouncedGetUser(id);
  }
  async function getUser(id) {
    const response = await fetch(`${variables.basePath}/api/user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "secret_token": token,
      },
    });
    const res = await response.json();
    setContactInfo(res?.result);
  }

  async function handleSubmit() {
    const response = await fetch(`${variables.basePath}/api/user/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "secret_token": token,
      },
      body: JSON.stringify(contactInfo),
    });
    const res = await response.json();
    if (res.success) {
      Swal.fire({
        title: 'Success!',
        text: 'You have successfully updated the user',
        icon: 'success',
        confirmButtonText: 'Cool'
      })
      navigate(`/members`);
    }
    setEditEnable(false);
  }

  function handleDiscard() {
    setEditEnable(false);
    setContactInfo(location.state);
    window.scrollTo(0, 0);
  }

  async function putAsPending() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You are going to put this user as pending!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Put as pending!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const baseURL = window.location.origin;
        const response = await fetch(`${variables.basePath}/api/user/pending`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "secret_token": token,
          },
          body: JSON.stringify({
            userID: id,
            baseURL: baseURL
          }),
        });

        const res = await response.json();
        if (res.success) {
          setContactInfo(res?.result);
          Swal.fire(
            'User put as pending!',
          )
        } else {
          Swal.fire(
            'Something went wrong!',
          )
        }
      }
    });
  }

  async function acceptMember() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You are going to accept this user!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Accept!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const baseURL = window.location.origin;
        const response = await fetch(`${variables.basePath}/api/user/accept`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "secret_token": token,
          },
          body: JSON.stringify({
            userID: id,
            baseURL: baseURL
          }),
        });

        const res = await response.json();
        if (res.success) {
          setContactInfo(res?.result);
          Swal.fire(
            'User Accepted!',
          )
        } else {
          Swal.fire(
            'Something went wrong!',
          )
        }
      }
    });
  }

  async function rejectMember() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You are going to reject this user!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Reject!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await fetch(`${variables.basePath}/api/user/reject`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "secret_token": token,
          },
          body: JSON.stringify({
            userID: id,
          }),
        });

        const res = await response.json();
        if (res.success) {
          setContactInfo(res?.result);
          Swal.fire(
            'User Rejected!',
          )
        } else {
          Swal.fire(
            'Something went wrong!',
          )
        }
      }
    });
  }

  async function deactivateUser() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You are going to deactivate this user!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Deactivate!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await fetch(`${variables.basePath}/api/user/deactivate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "secret_token": token,
          },
          body: JSON.stringify({
            userID: id,
          }),
        });

        const res = await response.json();
        if (res.success) {
          setContactInfo(res?.result);
          Swal.fire(
            'User Deactivated!',
          )
        } else {
          Swal.fire(
            'Something went wrong!',
          )
        }
      }
    });
  }

  async function activateUser() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You are going to activate this user!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Activate!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await fetch(`${variables.basePath}/api/user/activate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "secret_token": token,
          },
          body: JSON.stringify({
            userID: id,
          }),
        });

        const res = await response.json();
        if (res.success) {
          setContactInfo(res?.result);
          Swal.fire(
            'User Activated!',
          )
        } else {
          Swal.fire(
            'Something went wrong!',
          )
        }
      }
    });
  }

  async function requestPayment() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You are going to request payment from this user!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Request!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const baseURL = window.location.origin;
        const response = await fetch(`${variables.basePath}/api/user/payment/request`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "secret_token": token,
          },
          body: JSON.stringify({
            userID: id,
            baseURL: baseURL
          }),
        });

        const res = await response.json();
        if (res.success) {
          Swal.fire(
            'Payment Requested!',
          )
        } else {
          Swal.fire(
            'Something went wrong!',
          )
        }
      }
    });
  }

  async function paymentReceived() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Payment received from this user?!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Received!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await fetch(`${variables.basePath}/api/user/payment/received`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "secret_token": token,
          },
          body: JSON.stringify({
            userID: id,
          }),
        });

        const res = await response.json();
        if (res.success) {
          setContactInfo(res?.result);
          Swal.fire(
            'Payment Updated!',
          )
        } else {
          Swal.fire(
            'Something went wrong!',
          )
        }
      }
    });
  }

  async function deleteUser() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You are going to delete this user. You cannot undo this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await fetch(`${variables.basePath}/api/user/delete`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "secret_token": token,
          },
          body: JSON.stringify({
            userID: id,
          }),
        });

        const res = await response.json();
        if (res.success) {
          Swal.fire({
            title: 'User Deleted!',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK!'
          }).then((result) => {
            navigate(`/members`);
          });
        } else {
          Swal.fire(
            'Something went wrong!',
          )
        }
      }
    });
  }

  const handleImageURL = (value) => {
    setImageURL(value?.url);
    setContactInfo(prevState => ({ ...prevState, profilePic: value?.url }))
    setContactInfo(prevState => ({ ...prevState, rescaledProfilePic: value?.rescaledURL }))
  };

  const handlePhoneNumber = (value) => {
    setContactInfo(prevState => ({ ...prevState, mobileNumber: value }))
  };

  const handleModal = (value) => {
    setShowUploader(value);
  };

  return (
    <>
      {isAdmin ?
        <div className="member-inner">
          <div className='member-inner-header'>
            <div className='container'>
              <div className="heading-container">
                <img className="profile-picture" src={contactInfo?.profilePic} alt="" width={80} />
                <h1 className=''>{contactInfo?.foreName} {contactInfo?.lastName}
                  {contactInfo?.approval === 'verified' ?
                    <i className="bi bi-check-circle-fill m-l-5 fs-18" style={{ color: '#5ea5e7' }}></i>
                    : null}
                  {contactInfo?.approval === 'pending' ?
                    <span className="pending-badge-inner">
                      Pending Review
                    </span>
                    : null}
                  {contactInfo?.approval === 'accepted' && contactInfo?.payment === 'pending' ?
                    <span className="pending-badge-inner">
                      User Accepted | Payment Pending
                    </span>
                    : null}
                  {contactInfo?.approval === 'accepted' && contactInfo?.payment === 'success' ?
                    <span className="pending-badge-inner">
                      User Accepted | Payment Completed
                    </span>
                    : null}
                  {contactInfo?.approval === 'deactivated' ?
                    <span className="pending-badge-inner">
                      User Deactivated
                    </span>
                    : null}
                  {contactInfo?.approval === 'rejected' ?
                    <span className="pending-badge-inner">
                      User Rejected
                    </span>
                    : null}
                </h1>
              </div>
            </div>

            <div className="settings">

              <Dropdown>
                <Dropdown.Toggle variant="transperent" id="dropdown-basic">
                  <span className="material-icons-outlined">
                    settings
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {
                    contactInfo?.approval === 'pending' ?
                      <>
                        <Dropdown.Item onClick={() => acceptMember()}>Accept membership</Dropdown.Item>
                        <Dropdown.Item onClick={() => rejectMember()}>Reject membership</Dropdown.Item>
                      </>
                      : null
                  }
                  {
                    contactInfo?.approval === 'accepted' ?
                      <>
                        <Dropdown.Item onClick={() => deactivateUser()}>Deactivate this account</Dropdown.Item>
                      </>
                      : null
                  }
                  {
                    contactInfo?.approval === 'accepted' && contactInfo?.payment === 'pending' ?
                      <>
                        <Dropdown.Item onClick={() => requestPayment()}>Request payment</Dropdown.Item>
                        <Dropdown.Item onClick={() => paymentReceived()}>Payment received</Dropdown.Item>
                      </>
                      : null
                  }
                  {
                    contactInfo?.approval === 'rejected' ?
                      <>
                        <Dropdown.Item onClick={() => putAsPending()}>Put as pending this account</Dropdown.Item>
                      </>
                      : null
                  }
                  {
                    contactInfo?.approval === 'deactivated' ?
                      <>
                        <Dropdown.Item onClick={() => activateUser()}>Activate again this user </Dropdown.Item>
                      </>
                      : null
                  }
                  {
                    contactInfo?.approval === 'deactivated' || contactInfo?.approval === 'rejected' ?
                      <>
                        <Dropdown.Item onClick={() => deleteUser()}>Delete this user</Dropdown.Item>
                      </>
                      : null
                  }
                  {
                    editEnable ?
                      <Dropdown.Item onClick={() => handleSubmit()}>Save</Dropdown.Item>
                      : null
                  }
                  {
                    !editEnable && contactInfo?.approval !== 'deactivated' ?
                      <Dropdown.Item onClick={() => setEditEnable(true)}>Edit profile</Dropdown.Item>
                      : null
                  }

                </Dropdown.Menu>
              </Dropdown>
            </div>

          </div>
          <Container className="d-flex justify-content-center">
            <Form onSubmit={handleSubmit} >
              <Col md={10} className="mt-5 mx-auto">
                <Row>
                  <Col md={2}>
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <CreatableSelect
                        options={titleOptions}
                        value={[{ label: contactInfo?.title, value: contactInfo?.title }]}
                        onChange={(e) => setContactInfo(prevState => ({ ...prevState, title: e.value }))}
                        placeholder="Select Position"
                        disabled={!editEnable}
                      />
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="mb-3">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={contactInfo?.foreName}
                        onChange={(e) => setContactInfo(prevState => ({ ...prevState, foreName: e.target.value }))}
                        disabled={!editEnable}
                      />
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="mb-3">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={contactInfo?.lastName}
                        onChange={(e) => setContactInfo(prevState => ({ ...prevState, lastName: e.target.value }))}
                        disabled={!editEnable}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label className="form-label">Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={contactInfo?.fullName}
                        onChange={(e) => setContactInfo(prevState => ({ ...prevState, fullName: e.target.value }))}
                        disabled={!editEnable}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className=''>
                  <Col md={12}>
                    <div className="mb-3">
                      <label className="form-label">Date Of Birth</label>
                      <input
                        type="date"
                        className="form-control"
                        value={dayjs(contactInfo?.dateOfBirth).format('YYYY-MM-DD')}
                        onChange={(e) => setContactInfo(prevState => ({ ...prevState, dateOfBirth: e.target.value }))}
                        disabled={!editEnable}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className=''>
                  <Col md={12}>
                    <Row className="mb-3">
                      <label className="form-label">Residential Address</label>

                      <div className="col-md-6 m-b-15">
                        <input
                          className="form-control"
                          placeholder="Street Address 1"
                          value={contactInfo?.address.address1}
                          onChange={(e) => setContactInfo(prevState => ({
                            ...prevState,
                            address: {
                              ...prevState.address,
                              address1: e.target.value
                            }
                          }))}
                          disabled={!editEnable}
                        />
                      </div>
                      <div className="col-md-6 m-b-15">
                        <input
                          className="form-control"
                          placeholder="Street Address 2"
                          value={contactInfo?.address.address2}
                          onChange={(e) => setContactInfo(prevState => ({
                            ...prevState,
                            address: {
                              ...prevState.address,
                              address2: e.target.value
                            }
                          }))}
                          disabled={!editEnable}
                        />


                      </div>
                      <div className="col-md-4 m-b-15">
                        <input
                          className="form-control"
                          placeholder="City"
                          value={contactInfo?.address.city}
                          onChange={(e) => setContactInfo(prevState => ({
                            ...prevState,
                            address: {
                              ...prevState.address,
                              city: e.target.value
                            }
                          }))}
                          disabled={!editEnable}
                        />


                      </div>
                      <div className="col-md-4 m-b-15">
                        <input
                          className="form-control"
                          placeholder="State/Province/Region"
                          value={contactInfo?.address.state}
                          onChange={(e) => setContactInfo(prevState => ({
                            ...prevState,
                            address: {
                              ...prevState.address,
                              state: e.target.value
                            }
                          }))}
                          disabled={!editEnable}
                        />


                      </div>
                      <div className="col-md-4 m-b-15">
                        <input
                          className="form-control"
                          placeholder="Zip Code"
                          value={contactInfo?.address.zip}
                          onChange={(e) => setContactInfo(prevState => ({
                            ...prevState,
                            address: {
                              ...prevState.address,
                              zip: e.target.value
                            }
                          }))}
                          disabled={!editEnable}
                        />

                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row className=''>
                  <Col md={12}>
                    <div className="mb-3">
                      <label className="form-label">Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        value={contactInfo?.email}
                        onChange={(e) => setContactInfo(prevState => ({ ...prevState, email: e.target.value }))}
                        disabled={!editEnable}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className=''>
                  <div className="col-md-6 m-b-15">
                    <label htmlFor="" className='form-label'>Mobile Number</label>
                    <div className="row">
                      <div>
                        <PhoneInput
                          className="form-control d-flex"
                          international
                          defaultCountry="LK"
                          value={contactInfo?.mobileNumber}
                          onChange={handlePhoneNumber}
                          disabled={!editEnable} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 m-b-15">
                    <input
                      placeholder="Mobile Number"
                      id="flexCheckDefault"
                      className="form-check-input"
                      type="checkbox"
                      value={contactInfo?.imOnWhatsapp}
                      checked={contactInfo?.imOnWhatsapp}
                      onChange={(e) => setContactInfo(prevState => ({ ...prevState, imOnWhatsapp: e.target.checked }))}
                      disabled={!editEnable}
                    />
                    <label className="m-l-10 form-check-label" htmlFor="flexCheckDefault">I’m on WhatsApp on this number</label>
                  </div>

                </Row>

                <Row className=''>
                  <Col md={12}>
                    <div className="mb-3">
                      <label className="form-label">ID Number/ Passport Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={contactInfo?.idORPassportNumber}
                        onChange={(e) => setContactInfo(prevState => ({ ...prevState, idORPassportNumber: e.target.value }))}
                        disabled={!editEnable}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className=''>
                  <Col md={6}>
                    <div className="mb-3">
                      <label className="form-label">Profession</label>
                      <input
                        type="text"
                        className="form-control"
                        value={contactInfo?.profession}
                        onChange={(e) => setContactInfo(prevState => ({ ...prevState, profession: e.target.value }))}
                        disabled={!editEnable}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <label className="form-label">Designation</label>
                      <input
                        type="text"
                        className="form-control"
                        value={contactInfo?.designation}
                        onChange={(e) => setContactInfo(prevState => ({ ...prevState, designation: e.target.value }))}
                        disabled={!editEnable}
                      />
                    </div>
                  </Col>
                </Row>
                <Col md={12}>
                  <div className="mb-3">
                    <label className="form-label">Preferred Position at OAPF</label>
                    <Select
                      options={professionList}
                      value={[{ label: contactInfo?.preferredPosition?.replace(/_/g, " "), value: contactInfo?.preferredPosition }]}
                      onChange={(e) => setContactInfo(prevState => ({ ...prevState, preferredPosition: e.value }))}
                      placeholder="Select Position"
                      disabled={!editEnable}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <div className="mb-3">
                    <label className="form-label">Reference</label>
                    <input
                      type="text"
                      className="form-control"
                      value={contactInfo?.reference}
                      onChange={(e) => setContactInfo(prevState => ({ ...prevState, reference: e.target.value }))}
                      disabled={!editEnable}
                    />
                  </div>
                </Col>

                {editEnable ? (
                  <div className="col-md-12 m-b-15">
                    <label htmlFor="">Update Profile Photo</label>
                    <p className='upload-button form-control w-33' onClick={showUpload}>
                      Upload Image
                      <span className="material-icons-outlined">
                        file_upload
                      </span>
                    </p>
                    {imageURL ? <img src={imageURL} className="uploaded-pic m-t-10" alt="" width={100} height={100} /> : null}
                    <div>
                      {maximumSizeError && (
                        <span className="text-danger fs-14">Maximum image size should be 5MB. Please choose a smaller image.</span>
                      )}
                    </div>
                  </div>
                ) : (null)}


                <ImageUploader show={showUploader} returnURL={handleImageURL} handleModal={handleModal} setMaximumSizeError={setMaximumSizeError} />

                {
                  editEnable === (true) ?

                    <Col md={12} >
                      <div className="mb-3 d-flex justify-content-between">
                        <Button variant='outline-dark' onClick={() => handleDiscard()}>Discard</Button>
                        <Button variant='dark' onClick={() => handleSubmit()}>Save</Button>
                      </div>
                    </Col>

                    : <div className='p-5' />
                }
              </Col>
            </Form>
          </Container>
        </div>
        : <p className='d-flex align-items-center justify-content-center font-mono v-h-80'>Page not found</p>}
    </>
  )
}
