import React, { useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import debounce from 'lodash.debounce';
import { variables } from "../../stores/backend";
import Swal from 'sweetalert2';

export default function Content() {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // API call
        const response = await fetch(`${variables.basePath}/api/payment/check/${id}`, {
          method: "GET",
        });
        const res = await response.json();

        if (res.responseCode===10) {
          navigate(`/payment-successful/${id}`, { state: res?.user });
        } else if(res.responseCode===20){
          navigate(`/payment-summary/${id}`, { state: res?.user });
        }else{
          Swal.fire({
            title: 'Something went wrong.',
            text: "Please try again. If the problem persist contact our membership secretary at +94 77 200 1078",
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Okay!'
          }).then(async (result) => {
            if (result.isConfirmed) {
              navigate(`/members`);
            }
          });
        }
        // Handle the API response
      } catch (error) {
        Swal.fire({
          title: 'Something went wrong.',
          text: "Please try again. If the problem persist contact our membership secretary at +94 77 200 1078",
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Okay!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate(`/members`);
          }
        });
      }
    };
    const debouncedFetchData = debounce(fetchData, 2000);
    debouncedFetchData();
  });

  return (
    <div className="payment-check">
      <Spinner animation="grow" />
    </div>
  )
}
