import React, { useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import { useAuth } from '../AuthProvider/AuthProvider';
import HeaderMobile from './Mobile';

export default function Content() {
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const [admin] = useState(isAdmin ? JSON.parse(sessionStorage.getItem("adminUser")) : null)

  const logout = () => {
    sessionStorage.clear()
    navigate(`/members`);
    window.location.reload();
  };
  return (
    <>
      <div className='header'>
        <div className="">
          <NavLink activeclassname='active' className="menu-link" to='https://www.oapf.org/'>Home</NavLink>
          <NavLink activeclassname='active' className="menu-link" to='https://www.oapf.org/projects/'>Projects</NavLink>
          <NavLink activeclassname='active' className="menu-link" to='https://www.oapf.org/about/'>About</NavLink>
          <NavLink activeclassname='active' className="menu-link" to='/members'>Members</NavLink>
        </div>
        <img className='logo' src="/assets/images/logos@2x.png" alt=""/>
        <div className="">
          <NavLink activeclassname='active' className="menu-link" to='https://www.oapf.org/contact/'>Contact</NavLink>
          <NavLink activeclassname='active' className=" register-button" to='/register'>Become a member</NavLink>
          <NavLink activeclassname='active' className=" get-involved-button" to='https://www.oapf.org/contact/'>Get Involved</NavLink>
          {isAdmin ? (
            <Dropdown>
              <Dropdown.Toggle variant="transperent" id="userProfile" className=''>
                {/* <p className='d-inline-flex overline m-b-0'>{admin.foreName}</p> */}
                <img src={admin?.profilePic} alt="" className='header-profile' />

              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
                <Dropdown.Item onClick={() => { navigate(`/member-inner/${admin?._id}`, { state: admin }) }}>View Profile</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (null)}
        </div>
      </div>
      <HeaderMobile />
    </>
  )
}
