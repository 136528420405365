import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, FloatingLabel, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { variables } from "../../stores/backend";

export default function Content() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  async function loginUser() {
    const response = await fetch(`${variables.basePath}/api/auth/admin/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "username": username,
        "password": password,
      }),
    });
    const res = await response.json();
    if (res?.success) {
      sessionStorage.setItem('isAdmin', 'true');
      sessionStorage.setItem('token', res?.token);
      sessionStorage.setItem('adminUser', JSON.stringify(res?.result));
      navigate(`/members`);
      window.location.reload()
    } else {
      Swal.fire({
        title: 'Error!',
        text: res?.info?.message,
        icon: 'error',
        confirmButtonText: 'OK'
      })
    }
  }

  return (
    <div className="login">
      <div className="row">
        <div className="col-md-6 login-backdrop"></div>
        <div className="col-md-6 login-container">
          <div className="back-button p-t-20 p-l-10">
            <Link to={"/"}>
              <span className="material-icons-outlined">keyboard_backspace</span>Back to Home Page</Link>
          </div>
          <div className="login-form d-flex flex-column justify-content-center align-items-center">
            <div className='text-center'>
              <img src="assets/images/logos@2x.png" alt="" width={224} className='m-b-20' />
              <h2 className='text-center'>Join us and be part of<br />Ananda College's Future</h2>
            </div>
            <div className="m-t-50 w-60 text-center">
              <FloatingLabel controlId="floatingInput" label="Username" className="m-b-15">
                <Form.Control type="email" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
              </FloatingLabel>
              <FloatingLabel controlId="floatingPassword" label="Password" className='m-b-35'>
                <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
              </FloatingLabel>
              <Button variant='login' onClick={() => loginUser()}>Login</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
