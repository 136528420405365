import React from 'react';
import StaticContent from './StaticContent';
import Form from './Form';
import { Link } from 'react-router-dom';

export default function Content() {

  return (
    <div className="register">
      <div className="section">
      <div className="container">
        <div className="heading m-b-40">
          <div className="back-button m-b-10">
            <Link to={"/"}>
              <span className="material-icons-outlined">keyboard_backspace</span>Back to Home Page</Link>
          </div>
          <div>
          <img src="assets/images/logos@2x.png" alt="" width={112} />
          <h2 className="p-t-20 p-b-20">Join us and be part of Ananda College's Future</h2>
          </div>
        
        </div>
        </div>

      </div>
  
        <div className="container">
        <div className="row">
          <div className="col-lg-4 instructions">
            <StaticContent />
          </div>
          <div className="col-lg-8 form-wrapper">
            <Form />
          </div>
        </div>
      </div>
    
      </div>
  )
}
