import React, { useState, useContext } from 'react';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isAdmin, setIsAdmin] = useState(
    sessionStorage.getItem('isAdmin') === 'true'
  );
  const [token] = useState(sessionStorage.getItem('token'));
  const [adminUser] = useState(sessionStorage.getItem('adminUser'));

  const login = () => {
    setIsAdmin(true);
    sessionStorage.setItem('isAdmin', 'true');
  };

  const logout = () => {
    setIsAdmin(false);
    sessionStorage.setItem('isAdmin', 'false');
  };

  return (
    <AuthContext.Provider value={{ isAdmin, token, adminUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
