import React, { useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export default function Content() {
  const location = useLocation();
  const navigate = useNavigate();

  const [contactInfo] = useState(location.state);

  async function gotoMembers() {
    navigate(`/members`);
  }

  return (
    <>
      {contactInfo ?
        <div className="payment-summary">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 p-t-r">
                <div>
                  <img src="/assets/images/logo-glyph-gold_c.png" alt="" width={65} height={69} className="m-b-20" />
                  <h1 className="m-b-25 title-h1">Payment<br />Successfull</h1>

                  <p className="sm m-w-60 m-b-25">You are now a member of OAPF Old Anandians’ Professional Forum.</p>

                </div>
              </div>


              <div className="d-block d-lg-none d-xl-none d-xl-block p-t-r d-flex flex-column align-items-end flex-wrap">

                <div className="d-flex flex-wrap justify-content-center">

                  <div className="flip-card" tabIndex="0">
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <div className="membership-card-front   ">
                          <p className="card-name">{contactInfo?.title}. {contactInfo?.foreName} <br /> {contactInfo?.lastName}</p>
                          <p className="card-number">{contactInfo?.OAPFNumber}</p>
                          <p className="card-id">{contactInfo?.idORPassportNumber}</p>
                          <img src={contactInfo?.profilePic} alt="" className="card-profile" />
                        </div>
                      </div>
                      <div className="flip-card-back">
                        <div className="membership-card-back">
                          <p className="card-secret-number">
                            L&lt;M&lt;
                            {dayjs(contactInfo?.createdAt).format('YYYY')}&lt;
                            {dayjs(contactInfo?.createdAt).format('MM')}&lt;
                            {dayjs(contactInfo?.createdAt).format('DD')}&lt;&lt;&lt;
                            {contactInfo?.foreName}&lt;
                            {contactInfo?.lastName}&lt;&lt;&lt;&lt;
                            {contactInfo?.idORPassportNumber}&lt;&lt;&lt;&lt;
                            {contactInfo?.OAPFNumber}&lt;&lt;&lt;&lt;
                            {contactInfo?.OAPFNumber}&lt;&lt;&lt;&lt;
                            {contactInfo?.OAPFNumber}&lt;&lt;&lt;&lt;
                            {contactInfo?.OAPFNumber}&lt;
                          </p>
                          <p className="card-signature">{contactInfo?.lastName}</p>
                          <img src="/assets/images/qr-code.png" alt="" className="card-qrcode" />
                          <img src={contactInfo?.profilePic} alt="" className="card-profile" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="fs-12 text-hint text-center m-t-25"> Need to update any details on your card? No need to worry! We offer the flexibility to make changes even after your payment has been processed. Simply send us a request at <u>membership@oapf.org</u>, and we will gladly assist you with updating the necessary information.</p>
                </div>
              </div>



              <div className="payment-successful-card col-lg-4">

                <p className="overline-m m-b-20">WHATS NEXT?</p>
                <p className="fs-16 m-b-10">Our mission is to help everyone find their rightful place in the world of Ananda College. Talented individuals like you, inspire us and grow together. We appreciate the time and effort that you put into the application process.</p>
                <p className="fs-16 m-b-10">An email containing your payment receipt and virtual membership card will be sent to your registered email shortly. Additionally, you will receive a WhatsApp message with your virtual card to your mobile number.</p>
                <p className="fs-16 m-b-10"> We have also included your Name and designation in the membership directory on our website. Everything else will be private. Feel free to utilize the search field in the member directory to easily locate your name.</p>
                <p className="fs-13 m-b-30">If the registered phone number does not have WhatApp Installed you may not receive any messages. Not to worry as an extra precaution, we have also emailed you the card. If you wish to opt-out of the membership directory please send us an email at membership@oapf.org</p>

                <div className="card-header">
                  <p className="overline-sm m-b-20">PAYMENT DETAILS</p>
                </div>
                <div className="card-description">
                  <p className="hint-text overline-sm m-b-0">Payment Status</p>
                  <span className="d-flex flex-row align-items-center">
                    <i className="d-flex flex-row align-items-center m-r-5" ><svg xmlns="http://www.w3.org/2000/svg" height="24" fill="#24904A" viewBox="0 -960 960 960" width="24"><path d="m429-336 238-237-51-51-187 186-85-84-51 51 136 135Zm51 240q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Z" /></svg></i>   <p className="fs-14 m-b-0 text-success">Successful</p>
                  </span>

                </div>
                <div className="card-description">
                  <p className="hint-text overline-sm m-b-0">Life Membership fee</p>
                  <p className="fs-14 m-b-0">LKR 10,000</p>
                </div>
                <div className="card-description m-b-10">
                  <p className="hint-text overline-sm m-b-0">Convenience fee </p>
                  <p className="fs-14 m-b-0">LKR 267</p>
                </div>
                <div className="card-total">
                  <p className="m-b-0">Total Paid</p>
                  <p className="m-b-0">LKR 10,267</p>
                </div>

                <div className="card-buttons m-b-60 m-t-30">
                  <button className='btn btn-payment' onClick={() => gotoMembers()}><span className="p-l-20">member directory</span> <img src="/assets/images/arrow-forward-03.svg" alt='arrow'></img></button>
                </div>

              </div>

              <div className="payment-content d-none d-lg-block d-xl-block col-lg-4 m-t-10 ">
                <div className="col-md-9 p-t-r d-flex flex-column align-items-end flex-wrap mx-auto">

                  <div className="d-flex flex-wrap justify-content-center">

                    <div className="flip-card" tabIndex="0">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <div className="membership-card-front   ">
                            <p className="card-name">{contactInfo?.title}. {contactInfo?.foreName} <br /> {contactInfo?.lastName}</p>
                            <p className="card-number">{contactInfo?.OAPFNumber}</p>
                            <p className="card-id">{contactInfo?.idORPassportNumber}</p>
                            <img src={contactInfo?.profilePic} alt="" className="card-profile" />
                          </div>
                        </div>
                        <div className="flip-card-back">
                          <div className="membership-card-back">
                            <p className="card-secret-number">
                              L&lt;M&lt;
                              {dayjs(contactInfo?.createdAt).format('YYYY')}&lt;
                              {dayjs(contactInfo?.createdAt).format('MM')}&lt;
                              {dayjs(contactInfo?.createdAt).format('DD')}&lt;&lt;&lt;
                              {contactInfo?.foreName}&lt;
                              {contactInfo?.lastName}&lt;&lt;&lt;&lt;
                              {contactInfo?.idORPassportNumber}&lt;&lt;&lt;&lt;
                              {contactInfo?.OAPFNumber}&lt;&lt;&lt;&lt;
                              {contactInfo?.OAPFNumber}&lt;&lt;&lt;&lt;
                              {contactInfo?.OAPFNumber}&lt;&lt;&lt;&lt;
                              {contactInfo?.OAPFNumber}&lt;
                            </p>
                            <p className="card-signature">{contactInfo?.lastName}</p>
                            <img src="/assets/images/qr-code.png" alt="" className="card-qrcode" />
                            <img src={contactInfo?.profilePic} alt="" className="card-profile" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="fs-12 text-hint text-center m-t-25"> Need to update any details on your card? No need to worry! We offer the flexibility to make changes even after your payment has been processed. Simply send us a request at <u>membership@oapf.org</u>, and we will gladly assist you with updating the necessary information.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        : <p className='d-flex align-items-center justify-content-center fs-18 v-h-80'>This page is not found</p>}
    </>
  )
}
